export const fetchData = async (
  url,
  method = "GET",
  body = null,
  options = {}
) => {
  try {
    const fetchOptions = {
      method,
      headers: {
        "Content-Type": "application/json",
        ...options.headers,
      },
      body: body ? JSON.stringify(body) : null,
      ...options,
    };

    const response = await fetch(url, fetchOptions);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return { data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};
