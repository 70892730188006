import styled from "styled-components";

export const HeaderBox = styled.div`
  display: flex;
  width: 100vw;
  height: 54px;
  background: #f7f2f9;
  border-bottom: 1px solid #e5e5e5;
`;

export const LeftHeaderBox = styled.div`
  width: 232px;
  height: 54px;
  display: flex;
  align-items: center;
  border-right: 1px solid #e5e5e5;
  box-sizing: border-box;
`;

export const RightHeaderBox = styled.div`
  flex: 1;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AppSwitcherBox = styled.div`
  width: 64px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e5e5e5;
  cursor: pointer;
  &:hover {
    background-color: #f5effc;
  }
`;

export const AppLogoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProductTitle = styled.div`
  margin-left: 16px;
  font-size: 16px;
  font-weight: 600;
`;

export const ProductLogoBox = styled.div`
  margin-left: 16px;
`;

export const GreetingsText = styled.div`
  font-weight: 400;
  margin-left: 16px;
  font-size: 14px;
`;

export const HeaderActionsBox = styled.div`
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ActionsIcon = styled.div`
  margin-left: 16px;
  cursor: pointer;
`;
