const SelectedTickIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} fill="none">
    <rect width={16} height={16} x={0.863} y={0.146} fill="#fff" rx={8} />
    <path
      fill="#42055F"
      d="M7.336 11.264a.75.75 0 0 1-.475-.17l-2.24-1.835a.75.75 0 0 1 .95-1.16l1.735 1.42 5-4.605a.75.75 0 1 1 1 1.1l-5.46 5.05a.74.74 0 0 1-.51.2Z"
    />
  </svg>
);
export default SelectedTickIcon;
