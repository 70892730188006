import React, { useState } from "react";
import {
  RightHeaderBox,
  GreetingsText,
  HeaderActionsBox,
  ActionsIcon,
} from "./styles";
import SeachIcon from "../Icons/SeachIcon";
import NotificationIcon from "../Icons/NotificationIcon";
import ProfileIcon from "../Icons/ProfileIcon";
import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import SeachBox from "./SeachBox";

const RightHeader: React.FC = () => {
  const [showSearchBox, setShowSearchBox] = useState(false);
  return (
    <>
      {!showSearchBox && (
        <RightHeaderBox>
          <GreetingsText>☀️️ Good morning Ashley!</GreetingsText>
          <HeaderActionsBox>
            <ActionsIcon
              onClick={() => {
                setShowSearchBox(true);
              }}
            >
              <SeachIcon />
            </ActionsIcon>
            <ActionsIcon>
              <NotificationIcon />
            </ActionsIcon>
            <ActionsIcon>
              <ProfileIcon />
            </ActionsIcon>
          </HeaderActionsBox>
        </RightHeaderBox>
      )}
      {showSearchBox && (
        <RightHeaderBox>
          <SeachBox setShowSearchBox={setShowSearchBox} />
        </RightHeaderBox>
      )}
    </>
  );
};

export default RightHeader;
