import styled from "styled-components";

export const NavBox = styled.div`
  width: 232px;
  background: #fdfcfe;
  border-right: 1px solid #f5effc;
  padding: 14px;
  box-sizing: border-box;
  transition: all 0.1s ease;
`;

export const NavActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const NavActionMenu = styled.div``;
