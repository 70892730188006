import ButtonStyles from "./ButtonStyles";
import TypographyStyles from "./TypographyStyles";
import DropdownStyles from "./DropdownStyles";
import { CssVarsProvider, extendTheme } from "@mui/joy/styles";

const secondary = {
  100: "#F4F5F7",
  200: "#E8E1EC",
  300: "#E8E1EC",
  400: "#E8E1EC",
  500: "#68377F",
  600: "#424242",
  700: "#42055F",
  800: "#42055F",
  900: "#42055F",
};

const primary = {
  100: "#F4F5F7",
  200: "#E8E1EC",
  300: "#E8E1EC",
  400: "#E8E1EC",
  500: "#68377F",
  600: "#424242",
  700: "#42055F",
  800: "#42055F",
  900: "#42055F",
};

const neutral = {
  100: "#F4F5F7",
  200: "#E8E1EC",
  300: "#E8E1EC",
  400: "#E8E1EC",
  500: "#68377F",
  600: "#424242",
  700: "#42055F",
  800: "#42055F",
  900: "#42055F",
};

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        neutral: {
          ...neutral,
          plainColor: `var(--joy-palette-neutral-600)`,
          plainHoverBg: `var(--joy-palette-neutral-100)`,
          plainActiveBg: `var(--joy-palette-neutral-200)`,
          plainDisabledColor: `var(--joy-palette-neutral-200)`,

          outlinedColor: `var(--joy-palette-neutral-500)`,
          outlinedBorder: `var(--joy-palette-neutral-200)`,
          outlinedHoverBg: `var(--joy-palette-neutral-100)`,
          outlinedHoverBorder: `var(--joy-palette-neutral-300)`,
          outlinedActiveBg: `var(--joy-palette-neutral-200)`,
          outlinedDisabledColor: `var(--joy-palette-neutral-100)`,
          outlinedDisabledBorder: `var(--joy-palette-neutral-100)`,

          softColor: `var(--joy-palette-neutral-600)`,
          softBg: `var(--joy-palette-neutral-100)`,
          softHoverBg: `var(--joy-palette-neutral-200)`,
          softActiveBg: `var(--joy-palette-neutral-300)`,
          softDisabledColor: `var(--joy-palette-neutral-300)`,
          softDisabledBg: `var(--joy-palette-neutral}-)50`,

          solidColor: "#fff",
          solidBg: `var(--joy-palette-neutral-500)`,
          solidHoverBg: `var(--joy-palette-neutral-600)`,
          solidActiveBg: `var(--joy-palette-neutral-700)`,
          solidDisabledColor: `#fff`,
          solidDisabledBg: `var(--joy-palette-neutral-200)`,
        },
        primary: {
          ...primary,
          plainColor: `var(--joy-palette-primary-600)`,
          plainHoverBg: `var(--joy-palette-primary-100)`,
          plainActiveBg: `var(--joy-palette-primary-200)`,
          plainDisabledColor: `var(--joy-palette-primary-200)`,

          outlinedColor: `var(--joy-palette-primary-500)`,
          outlinedBorder: `var(--joy-palette-primary-200)`,
          outlinedHoverBg: `var(--joy-palette-primary-100)`,
          outlinedHoverBorder: `var(--joy-palette-primary-300)`,
          outlinedActiveBg: `var(--joy-palette-primary-200)`,
          outlinedDisabledColor: `var(--joy-palette-primary-100)`,
          outlinedDisabledBorder: `var(--joy-palette-primary-100)`,

          softColor: `var(--joy-palette-primary-600)`,
          softBg: `var(--joy-palette-primary-100)`,
          softHoverBg: `var(--joy-palette-primary-200)`,
          softActiveBg: `var(--joy-palette-primary-300)`,
          softDisabledColor: `var(--joy-palette-primary-300)`,
          softDisabledBg: `var(--joy-palette-primary}-)50`,

          solidColor: "#fff",
          solidBg: `var(--joy-palette-primary-500)`,
          solidHoverBg: `var(--joy-palette-primary-600)`,
          solidActiveBg: `var(--joy-palette-primary-700)`,
          solidDisabledColor: `#fff`,
          solidDisabledBg: `var(--joy-palette-primary-200)`,
        },
        secondary: {
          ...secondary,
          plainColor: `var(--joy-palette-secondary-600)`,
          plainHoverBg: `var(--joy-palette-secondary-100)`,
          plainActiveBg: `var(--joy-palette-secondary-200)`,
          plainDisabledColor: `var(--joy-palette-secondary-200)`,

          outlinedColor: `var(--joy-palette-secondary-500)`,
          outlinedBorder: `var(--joy-palette-secondary-200)`,
          outlinedHoverBg: `var(--joy-palette-secondary-100)`,
          outlinedHoverBorder: `var(--joy-palette-secondary-300)`,
          outlinedActiveBg: `var(--joy-palette-secondary-200)`,
          outlinedDisabledColor: `var(--joy-palette-secondary-100)`,
          outlinedDisabledBorder: `var(--joy-palette-secondary-100)`,

          softColor: `var(--joy-palette-secondary-600)`,
          softBg: `var(--joy-palette-secondary-100)`,
          softHoverBg: `var(--joy-palette-secondary-200)`,
          softActiveBg: `var(--joy-palette-secondary-300)`,
          softDisabledColor: `var(--joy-palette-secondary-300)`,
          softDisabledBg: `var(--joy-palette-secondary}-)50`,

          solidColor: "#fff",
          solidBg: `var(--joy-palette-secondary-500)`,
          solidHoverBg: `var(--joy-palette-secondary-600)`,
          solidActiveBg: `var(--joy-palette-secondary-700)`,
          solidDisabledColor: `#fff`,
          solidDisabledBg: `var(--joy-palette-secondary-200)`,
        },
      },
    },
    dark: {
      palette: {
        primary: {
          ...primary,
          plainColor: `var(--joy-palette-primary-300)`,
          plainHoverBg: `var(--joy-palette-primary-800)`,
          plainActiveBg: `var(--joy-palette-primary-700)`,
          plainDisabledColor: `var(--joy-palette-primary-800)`,

          outlinedColor: `var(--joy-palette-primary-200)`,
          outlinedBorder: `var(--joy-palette-primary-700)`,
          outlinedHoverBg: `var(--joy-palette-primary-800)`,
          outlinedHoverBorder: `var(--joy-palette-primary-600)`,
          outlinedActiveBg: `var(--joy-palette-primary-900)`,
          outlinedDisabledColor: `var(--joy-palette-primary-800)`,
          outlinedDisabledBorder: `var(--joy-palette-primary-800)`,

          softColor: `var(--joy-palette-primary-200)`,
          softBg: `var(--joy-palette-primary-900)`,
          softHoverBg: `var(--joy-palette-primary-800)`,
          softActiveBg: `var(--joy-palette-primary-700)`,
          softDisabledColor: `var(--joy-palette-primary-800)`,
          softDisabledBg: `var(--joy-palette-primary-900)`,

          solidColor: `#fff`,
          solidBg: `var(--joy-palette-primary-600)`,
          solidHoverBg: `var(--joy-palette-primary-700)`,
          solidActiveBg: `var(--joy-palette-primary-800)`,
          solidDisabledColor: `var(--joy-palette-primary-700)`,
          solidDisabledBg: `var(--joy-palette-primary-900)`,
        },
      },
    },
  },
});

function AppDesignSystem() {
  return (
    <CssVarsProvider theme={theme}>
      <div style={{ display: "flex" }}>
        <ButtonStyles />
        <TypographyStyles />
        <DropdownStyles />
      </div>
    </CssVarsProvider>
  );
}

export default AppDesignSystem;
