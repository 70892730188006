import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";

const TypographyStyles = () => {
  return (
    <Box sx={{ p: 2, border: "1px dashed grey", width: "200px" }}>
      <Typography color="neutral" level="h1">
        h1
      </Typography>
      <Typography color="neutral" level="h2">
        h2
      </Typography>
      <Typography color="neutral" level="h3">
        h3
      </Typography>
      <Typography color="neutral" level="h4">
        h4
      </Typography>
      <Typography color="neutral" level="title-lg">
        title-lg
      </Typography>
      <Typography color="neutral" level="title-md">
        title-md
      </Typography>
      <Typography color="neutral" level="title-sm">
        title-sm
      </Typography>
      <Typography color="neutral" level="body-lg">
        body-lg
      </Typography>
      <Typography color="neutral" level="body-md">
        body-md
      </Typography>
      <Typography color="neutral" level="body-sm">
        body-sm
      </Typography>
      <Typography color="neutral" level="body-xs">
        body-xs
      </Typography>
      <br />
      <Typography color="primary" level="h1">
        h1
      </Typography>
      <Typography color="primary" level="h2">
        h2
      </Typography>
      <Typography color="primary" level="h3">
        h3
      </Typography>
      <Typography color="primary" level="h4">
        h4
      </Typography>
      <Typography color="primary" level="title-lg">
        title-lg
      </Typography>
      <Typography color="primary" level="title-md">
        title-md
      </Typography>
      <Typography color="primary" level="title-sm">
        title-sm
      </Typography>
      <Typography color="primary" level="body-lg">
        body-lg
      </Typography>
      <Typography color="primary" level="body-md">
        body-md
      </Typography>
      <Typography color="primary" level="body-sm">
        body-sm
      </Typography>
      <Typography color="primary" level="body-xs">
        body-xs
      </Typography>
      <br />
      <Typography color="secondary" level="h1">
        h1
      </Typography>
      <Typography color="secondary" level="h2">
        h2
      </Typography>
      <Typography color="secondary" level="h3">
        h3
      </Typography>
      <Typography color="secondary" level="h4">
        h4
      </Typography>
      <Typography color="secondary" level="title-lg">
        title-lg
      </Typography>
      <Typography color="secondary" level="title-md">
        title-md
      </Typography>
      <Typography color="secondary" level="title-sm">
        title-sm
      </Typography>
      <Typography color="secondary" level="body-lg">
        body-lg
      </Typography>
      <Typography color="secondary" level="body-md">
        body-md
      </Typography>
      <Typography color="secondary" level="body-sm">
        body-sm
      </Typography>
      <Typography color="secondary" level="body-xs">
        body-xs
      </Typography>
      <br />
      <Typography color="danger" level="h1">
        h1
      </Typography>
      <Typography color="danger" level="h2">
        h2
      </Typography>
      <Typography color="danger" level="h3">
        h3
      </Typography>
      <Typography color="danger" level="h4">
        h4
      </Typography>
      <Typography color="danger" level="title-lg">
        title-lg
      </Typography>
      <Typography color="danger" level="title-md">
        title-md
      </Typography>
      <Typography color="danger" level="title-sm">
        title-sm
      </Typography>
      <Typography color="danger" level="body-lg">
        body-lg
      </Typography>
      <Typography color="danger" level="body-md">
        body-md
      </Typography>
      <Typography color="danger" level="body-sm">
        body-sm
      </Typography>
      <Typography color="danger" level="body-xs">
        body-xs
      </Typography>
      <br />

      <Typography color="success" level="h1">
        h1
      </Typography>
      <Typography color="success" level="h2">
        h2
      </Typography>
      <Typography color="success" level="h3">
        h3
      </Typography>
      <Typography color="success" level="h4">
        h4
      </Typography>
      <Typography color="success" level="title-lg">
        title-lg
      </Typography>
      <Typography color="success" level="title-md">
        title-md
      </Typography>
      <Typography color="success" level="title-sm">
        title-sm
      </Typography>
      <Typography color="success" level="body-lg">
        body-lg
      </Typography>
      <Typography color="success" level="body-md">
        body-md
      </Typography>
      <Typography color="success" level="body-sm">
        body-sm
      </Typography>
      <Typography color="success" level="body-xs">
        body-xs
      </Typography>
      <br />

      <Typography color="warning" level="h1">
        h1
      </Typography>
      <Typography color="warning" level="h2">
        h2
      </Typography>
      <Typography color="warning" level="h3">
        h3
      </Typography>
      <Typography color="warning" level="h4">
        h4
      </Typography>
      <Typography color="warning" level="title-lg">
        title-lg
      </Typography>
      <Typography color="warning" level="title-md">
        title-md
      </Typography>
      <Typography color="warning" level="title-sm">
        title-sm
      </Typography>
      <Typography color="warning" level="body-lg">
        body-lg
      </Typography>
      <Typography color="warning" level="body-md">
        body-md
      </Typography>
      <Typography color="warning" level="body-sm">
        body-sm
      </Typography>
      <Typography color="warning" level="body-xs">
        body-xs
      </Typography>
      <br />
    </Box>
  );
};

export default TypographyStyles;
