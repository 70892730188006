import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";

const ButtonStyles = () => {
  return (
    <Box
      component="section"
      sx={{ p: 2, border: "1px dashed grey", width: "200px" }}
    >
      Neutral:
      <Button color="neutral" variant="soft">
        Neutral
      </Button>
      <br />
      <br />
      primary:
      <Button color="primary" variant="soft">
        primary
      </Button>
      <br />
      Secondary:
      <Button color="secondary" variant="soft">
        Secondary
      </Button>
      <br />
      Danger:
      <Button color="danger" variant="soft">
        Danger
      </Button>
      <br />
      Success:
      <Button color="success" variant="soft">
        Success
      </Button>
      <br />
      Warning:
      <Button color="warning" variant="soft">
        Warning
      </Button>
    </Box>
  );
};

export default ButtonStyles;
