import React, { useState } from "react";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import {
  WorkspaceBox,
  WorkspaceHead,
  TitleBox,
  ActionsBox,
  TitleIcon,
  WorkspaceBody,
  TableToolbar,
  TableContainer,
  RightToolbar,
  LeftToolbar,
  ToolbarItem,
} from "./styles";
import Button from "@mui/joy/Button";
import ModuleIcon from "../Icons/ModuleIcon";
import Typography from "@mui/joy/Typography";
import ImportIcon from "../Icons/ImportIcon";
import SortIcon from "../Icons/SortIcon";
import ListIcon from "../Icons/ListIcon";
import SettingsIcon from "../Icons/SettingsIcon";
import FilterIcon from "../Icons/FilterIcon";
import AppTable from "../AppTable";
import Drawer from "@mui/joy/Drawer";
import AppFilter from "../AppFilter/index";
import { Outlet } from "react-router-dom";

const AppWorkspace = ({ itemSelected }) => {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <WorkspaceBox>
      <WorkspaceHead>
        <TitleBox>
          <TitleIcon>
            <ModuleIcon />
          </TitleIcon>
          <Typography fontSize="16px" level="title-lg" sx={{ ml: "16px" }}>
            {itemSelected}
          </Typography>
        </TitleBox>
        <ActionsBox>
          <Button
            sx={{
              backgroundColor: "#F4F5F7",
              color: "#3F3BE1",
              "&:hover": {
                backgroundColor: "#F2EDF5",
              },
            }}
            color="neutral"
            disabled={false}
            loading={false}
            onClick={function () {}}
            variant="soft"
            startDecorator={<ImportIcon />}
          >
            Import
          </Button>
        </ActionsBox>
      </WorkspaceHead>
      <WorkspaceBody>
        <TableToolbar>
          <RightToolbar>
            <Select
              color="neutral"
              variant="soft"
              size="sm"
              placeholder="Table view"
              sx={{
                width: 135,
              }}
            >
              <Option value="dog">Table view</Option>
              <Option value="cat">Kanban view</Option>
              <Option value="fish">List view</Option>
              <Option value="bird">Grid view</Option>
            </Select>
          </RightToolbar>
          <LeftToolbar>
            <ToolbarItem>
              <Button
                size="sm"
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                }}
                variant="soft"
                color="neutral"
                disabled={false}
                loading={false}
                onClick={function () {}}
                startDecorator={<SortIcon />}
                endDecorator={<ListIcon />}
              >
                Sort
              </Button>
            </ToolbarItem>
            <ToolbarItem>
              <Button
                size="sm"
                onClick={() => setShowDrawer(true)}
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                }}
                variant="soft"
                color="neutral"
                disabled={false}
                loading={false}
                startDecorator={<FilterIcon />}
                endDecorator={<ListIcon />}
              >
                Filter
              </Button>
            </ToolbarItem>
            <ToolbarItem>
              <Button
                size="sm"
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                }}
                variant="soft"
                color="neutral"
                disabled={false}
                loading={false}
                onClick={function () {}}
                startDecorator={<SettingsIcon />}
              >
                Settings
              </Button>
            </ToolbarItem>
          </LeftToolbar>
        </TableToolbar>
        <TableContainer>
          <Outlet />
        </TableContainer>
      </WorkspaceBody>
      <Drawer
        sx={{
          width: 420,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 420,
          },
        }}
        onClose={() => setShowDrawer(false)}
        anchor="right"
        open={showDrawer}
      >
        <AppFilter />
      </Drawer>
    </WorkspaceBox>
  );
};

export default AppWorkspace;
