import React, { useState, useEffect } from "react";
import {
  DataGridPro,
  GridActionsCellItem,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
} from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { getTableColumns, getTableRows } from "../Services";
import { useQuery } from "@tanstack/react-query";

const EditColumn = {
  field: "actions",
  type: "actions",
  headerName: "+ Add",
  width: 73,
  getActions: (params) => [
    <GridActionsCellItem
      icon={<EditIcon />}
      label="Edit"
      onClick={() => console.log("Edit", params.id)}
    />,
  ],
};

const AppTable = () => {
  const { tableId } = useParams();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const columnResponse = useQuery({
    queryKey: ["getTableColumns", tableId],
    queryFn: () => getTableColumns(tableId),
    enabled: !!tableId,
  });

  const rowResponse = useQuery({
    queryKey: ["getTableRows", tableId],
    queryFn: () => getTableRows(tableId),
    enabled: !!tableId,
  });

  useEffect(() => {
    const data = columnResponse.data;
    if (data) {
      const columns = data.map((item) => {
        return {
          field: item.internalName,
          headerName: item.label,
          width: 150,
        };
      });
      columns.push(EditColumn);
      setColumns(columns);
    }
  }, [columnResponse.data]);

  useEffect(() => {
    const data = rowResponse.data;
    if (data) {
      const rows = data.map((item, index) => {
        return {
          id: item.id,
          ...item.properties,
        };
      });
      setRows(rows);
    }
  }, [rowResponse.data]);

  return (
    <CssVarsProvider>
      <DataGridPro
        columnHeaderHeight={36}
        rowHeight={36}
        rows={rows}
        columns={columns}
        checkboxSelection
        initialState={{
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
            right: ["actions"],
          },
        }}
        sx={{
          border: 0,
          borderRadius: "0px",
          fontFamily: "BlinkMacSystemFont",
          "& .MuiDataGrid-columnHeader": {
            fontWeight: "400", // Optional: Set font weight of header titles
            backgroundColor: "#F5F8FE", // Set your desired background color here
            // borderRight: "1px solid #eceff2", // Optional: Add a bottom border to the header
            borderTop: "1px solid #eceff2",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "#E8E1EC", // Set your desired hover background color here
          },
          "& .MuiDataGrid-cell": {
            borderTop: "1px solid #eceff2", // Set your desired vertical line color and style
            // borderRight: "1px solid #eceff2", // Optional: Set the right border color and style
            border: "none", // Removes border from cells
          },
          "& .MuiSvgIcon-root": {
            fontSize: 18, // Adjust the size here
          },
          "& .MuiDataGrid-row": {
            borderBottom: "1px solid #eceff2",
          },

          "& .MuiCheckbox-root.Mui-checked": {
            color: "#551E6F", // Adjust the size here
          },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: "#F5EFFC", // Adjust the size here
          },
          "& .MuiDataGrid-row.Mui-selected:hover": {
            backgroundColor: "#F5EFFC", // Adjust the size here
          },
          "& .MuiDataGrid-cell:focus-within": {
            outlineColor: "#F5EFFC", // Adjust the size here
          },
          "& .MuiDataGrid-cell--pinnedLeft": {
            // backgroundColor: "#F5EFFC", // Adjust the size here
          },
          "& .MuiDataGrid-cell--pinnedLeft:hover": {
            // backgroundColor: "#F5EFFC !important", // Adjust the size here
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
        }}
      />
    </CssVarsProvider>
  );
};

export default AppTable;
