import * as React from "react";
const ListIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none">
    <path
      fill="#42055F"
      d="M10.277 7H3.863a.5.5 0 0 1 0-1h6.414a.5.5 0 1 1 0 1ZM3.863 9.333h8.547a.5.5 0 1 1 0 1H3.863a.5.5 0 1 1 0-1Z"
    />
  </svg>
);
export default ListIcon;
