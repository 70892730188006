const SeachIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none">
    <path
      fill="#6B778C"
      fillRule="evenodd"
      d="M3.475 6.39a7.675 7.675 0 0 1 7.064-4.724 7.675 7.675 0 1 1-4.483 13.875l-2.4 2.4a.625.625 0 0 1-.883-.883l2.325-2.325A7.675 7.675 0 0 1 3.475 6.39Zm1.114 5.417a6.434 6.434 0 0 0 5.95 3.967v-.033a6.433 6.433 0 0 0 6.425-6.392A6.433 6.433 0 1 0 4.59 11.807Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SeachIcon;
