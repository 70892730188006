import React from "react";
import { FilterBox, Header, Body, Footer } from "./styles";
import FilterIcon from "../Icons/FilterIcon";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import Input from "@mui/joy/Input";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab from "@mui/joy/Tab";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/joy/Button";
import Add from "@mui/icons-material/Add";
import Box from "@mui/joy/Box";

const AppFilter: React.FC = () => {
  return (
    <FilterBox>
      <Header>
        <FilterIcon />
        <Typography fontSize="16px" level="title-md" sx={{ ml: "14px" }}>
          Filter
        </Typography>
      </Header>
      <Divider />
      <Body>
        <Input
          color="neutral"
          variant="soft"
          placeholder="Filter name"
          size="sm"
        />
        <Tabs
          size="sm"
          color="neutral"
          sx={{
            mt: "20px",
            width: "100%",
          }}
        >
          <TabList
            tabFlex={1}
            color="neutral"
            variant="soft"
            disableUnderline
            sx={{
              p: 0.5,
              gap: 0.5,
            }}
          >
            <Tab>Default</Tab>
            <Tab>Advanced</Tab>
          </TabList>
        </Tabs>
        <FormControl
          sx={{
            mt: "20px",
            width: "100%",
          }}
        >
          <FormLabel
            id="select-field-demo-label"
            htmlFor="select-field-demo-button"
          >
            Property
          </FormLabel>
          <Select
            size="sm"
            color="neutral"
            indicator={<KeyboardArrowDown />}
            defaultValue="Property"
          >
            <Option value="Value">Value</Option>
          </Select>
        </FormControl>
        <Box sx={{ width: "100%", backgroundColor: "#FAFBFC" }}></Box>
      </Body>

      <Divider />
      <Footer></Footer>
    </FilterBox>
  );
};

export default AppFilter;
