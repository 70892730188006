import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AppLayout from "./AppLayout";
import AppInitializer from "./AppInitializer";
import { CssVarsProvider, extendTheme } from "@mui/joy/styles";
import AppDesignSystem from "./AppDesignSystem/index";
import AppTable from "./AppTable";

const primary = {
  100: "#F4F5F7",
  200: "#E8E1EC",
  300: "#E8E1EC",
  400: "#E8E1EC",
  500: "#68377F",
  600: "#42055F",
  700: "#42055F",
  800: "#42055F",
  900: "#42055F",
};

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          ...primary,
          plainColor: `var(--joy-palette-primary-600)`,
          plainHoverBg: `var(--joy-palette-primary-100)`,
          plainActiveBg: `var(--joy-palette-primary-200)`,
          plainDisabledColor: `var(--joy-palette-primary-200)`,

          outlinedColor: `var(--joy-palette-primary-500)`,
          outlinedBorder: `var(--joy-palette-primary-200)`,
          outlinedHoverBg: `var(--joy-palette-primary-100)`,
          outlinedHoverBorder: `var(--joy-palette-primary-300)`,
          outlinedActiveBg: `var(--joy-palette-primary-200)`,
          outlinedDisabledColor: `var(--joy-palette-primary-100)`,
          outlinedDisabledBorder: `var(--joy-palette-primary-100)`,

          softColor: `var(--joy-palette-primary-600)`,
          softBg: `var(--joy-palette-primary-100)`,
          softHoverBg: `var(--joy-palette-primary-200)`,
          softActiveBg: `var(--joy-palette-primary-300)`,
          softDisabledColor: `var(--joy-palette-primary-300)`,
          softDisabledBg: `var(--joy-palette-primary}-)50`,

          solidColor: "#fff",
          solidBg: `var(--joy-palette-primary-500)`,
          solidHoverBg: `var(--joy-palette-primary-600)`,
          solidActiveBg: `var(--joy-palette-primary-700)`,
          solidDisabledColor: `#fff`,
          solidDisabledBg: `var(--joy-palette-primary-200)`,
        },
      },
    },
  },
});

const queryClient = new QueryClient();

function App() {
  const baseName = window.location.pathname.startsWith("/lego") ? "/lego" : "/";
  return (
    <QueryClientProvider client={queryClient}>
      <CssVarsProvider theme={theme}>
        <AppInitializer />
        <Router basename={baseName}>
          <Routes>
            <Route path="/" element={<AppLayout />}>
              <Route path="/table-view/:tableId" element={<AppTable />} />
            </Route>
            <Route path="design-system" element={<AppDesignSystem />} />
          </Routes>
        </Router>
      </CssVarsProvider>
    </QueryClientProvider>
  );
}

export default App;
