import * as React from "react";
const ConnectAppIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} fill="none">
    <path
      fill="#42055F"
      d="M15.986 2.129h-10a3.333 3.333 0 0 0-3.334 3.333v10a3.333 3.333 0 0 0 3.334 3.334h10a3.333 3.333 0 0 0 3.333-3.334v-10a3.333 3.333 0 0 0-3.333-3.333Z"
    />
    <path
      fill="#fff"
      d="M14.315 9.837h-2.708V7.13a.625.625 0 0 0-1.25 0v2.708H7.648a.625.625 0 0 0 0 1.25h2.709v2.709a.625.625 0 1 0 1.25 0v-2.709h2.708a.625.625 0 1 0 0-1.25Z"
    />
  </svg>
);
export default ConnectAppIcon;
