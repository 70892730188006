import React, { useState } from "react";
import AppHeader from "../AppHeader";
import AppNavigation from "../AppNavigation";
import AppWorkspace from "../AppWorkspace";
import { Wrapper } from "./styles";

const AppLayout = () => {
  const [itemSelected, setItemSelected] = useState("");

  return (
    <div>
      <AppHeader />
      <Wrapper>
        <AppNavigation setItemSelected={setItemSelected} />
        <AppWorkspace itemSelected={itemSelected} />
      </Wrapper>
    </div>
  );
};

export default AppLayout;
