import * as React from "react";
const NotificationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none">
    <path
      fill="#6B778C"
      fillRule="evenodd"
      d="M17.679 12.9a1.667 1.667 0 0 1-.584-1.267V7.616c0-3.292-2.983-5.95-6.666-5.95-3.684 0-6.667 2.658-6.667 5.95v4.017c0 .487-.213.95-.583 1.266-1.259 1.125-.367 3.042 1.416 3.042h3.167a2.833 2.833 0 0 0 5.333 0h3.167c1.783 0 2.675-1.917 1.417-3.042Zm-7.25 3.666a1.608 1.608 0 0 1-1.267-.625h2.5a1.583 1.583 0 0 1-1.233.625Zm5.841-1.875a.733.733 0 0 0 .725-.408.375.375 0 0 0-.141-.45 2.991 2.991 0 0 1-1-2.2V7.616c0-2.592-2.434-4.7-5.417-4.7-2.983 0-5.417 2.108-5.417 4.7v4.017a2.992 2.992 0 0 1-1 2.2.375.375 0 0 0-.15.45.733.733 0 0 0 .725.408H16.27Z"
      clipRule="evenodd"
    />
  </svg>
);
export default NotificationIcon;
