import * as React from "react";
const CollapseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <path
      stroke="#6B778C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.333 11.333 4 7.999l3.333-3.333M12.001 11.333 8.668 7.999l3.333-3.333"
    />
  </svg>
);
export default CollapseIcon;
