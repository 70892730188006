import styled from "styled-components";

export const AppSelectorBox = styled.div`
  padding: 16px;
  background-color: #ffffff;
`;

export const HeaderBox = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: left;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`;

export const Body = styled.div``;

export const MenuHeader = styled.div`
  height: 36px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: left;
`;
