import React from "react";
import { AppSelectorBox, Header, Body, HeaderBox, MenuHeader } from "./styles";
import AppSwitchIcon from "../Icons/AppSwitchIcon";
import Typography from "@mui/joy/Typography";
import ConnectedIcon from "../Icons/ConnectedIcon";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import SelectedTickIcon from "../Icons/SelectedTickIcon";
import ExploreIcon from "../Icons/ExploreIcon";
import ConnectAppIcon from "../Icons/ConnectAppIcon";
import Box from "@mui/joy/Box";

const CONNECTED_APPS = [
  {
    id: 1,
    title: "CRM",
    isSelected: true,
  },
  {
    id: 2,
    title: "Project",
    isSelected: false,
  },
  {
    id: 3,
    title: "Admin",
    isSelected: false,
  },
];

const NOT_CONNECTED_APPS = [
  {
    id: 1,
    title: "Support",
    isSelected: false,
  },
];

const AppSelector: React.FC = () => {
  return (
    <AppSelectorBox>
      <Header>
        <HeaderBox>
          <AppSwitchIcon />
          <Typography fontSize="16px" level="h4" sx={{ ml: "16px" }}>
            Apps
          </Typography>
        </HeaderBox>
      </Header>
      <Body>
        <Box>
          <MenuHeader>
            <ConnectedIcon />
            <Typography
              fontSize="12px"
              fontWeight="600"
              sx={{ ml: "12px", color: "#6B778C" }}
            >
              Connected
            </Typography>
          </MenuHeader>
          <List size="sm">
            {CONNECTED_APPS.map((item, index) => (
              <ListItem
                key={index}
                sx={{
                  backgroundColor: "#FAFBFC",
                  height: "44px",
                  borderRadius: "8px",
                  marginBottom: "8px",
                  fontWeight: 600,
                }}
              >
                <ListItemButton
                  color="primary"
                  variant="plain"
                  selected={item.isSelected}
                  sx={{
                    borderRadius: "8px",
                    fontWeight: 600,
                    backgroundColor: "#FAFBFC",
                    "&:hover": {
                      backgroundColor: "var(--joy-palette-primary-200)",
                    },
                    "&.Mui-selected": {
                      color: "#ffffff",
                      fontWeight: 600,
                      backgroundColor: "var(--joy-palette-primary-600)",
                    },
                    "&.Mui-disabled": {
                      color: "var(--joy-palette-primary-300)",
                      backgroundColor: "var(--joy-palette-primary-50)",
                    },
                  }}
                >
                  <ListItemContent
                    sx={{
                      marginLeft: "16px",
                    }}
                  >
                    {item.title}
                  </ListItemContent>
                  <ListItemDecorator>
                    <SelectedTickIcon />
                  </ListItemDecorator>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box>
          <MenuHeader>
            <ConnectedIcon />
            <Typography
              fontSize="12px"
              fontWeight="600"
              sx={{ ml: "12px", color: "#6B778C" }}
            >
              Explore
            </Typography>
          </MenuHeader>
          <List size="sm">
            {NOT_CONNECTED_APPS.map((item, index) => (
              <ListItem
                key={index}
                sx={{
                  backgroundColor: "#FAFBFC",
                  height: "44px",
                  borderRadius: "8px",
                  marginBottom: "8px",
                  fontWeight: 600,
                }}
              >
                <ListItemButton
                  color="primary"
                  variant="plain"
                  selected={item.isSelected}
                  sx={{
                    borderRadius: "8px",
                    fontWeight: 600,
                    backgroundColor: "#FAFBFC",
                    "&:hover": {
                      backgroundColor: "var(--joy-palette-primary-200)",
                    },
                    "&.Mui-selected": {
                      color: "#ffffff",
                      fontWeight: 600,
                      backgroundColor: "var(--joy-palette-primary-600)",
                    },
                    "&.Mui-disabled": {
                      color: "var(--joy-palette-primary-300)",
                      backgroundColor: "var(--joy-palette-primary-50)",
                    },
                  }}
                >
                  <ListItemContent
                    sx={{
                      marginLeft: "16px",
                    }}
                  >
                    {item.title}
                  </ListItemContent>
                  <ListItemDecorator>
                    <ConnectAppIcon />
                  </ListItemDecorator>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Body>
    </AppSelectorBox>
  );
};

export default AppSelector;
