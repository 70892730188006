import React, { useEffect } from "react";
// import { getSessionData } from "../Services";

const AppInitializer: React.FC = () => {
  useEffect(() => {
    // getSessionData();
  }, []);

  return <div></div>;
};

export default AppInitializer;
