import styled from "styled-components";

export const FilterBox = styled.div``;

export const Header = styled.div`
  padding: 0px 16px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: left;
`;

export const Body = styled.div`
  padding: 20px;
  min-height: calc(100vh - 110px);
`;
export const Footer = styled.div``;
