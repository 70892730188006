import * as React from "react";
const SettingsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none">
    <path
      fill="#6B778C"
      fillRule="evenodd"
      d="M12.848 5.821c.127.31.427.512.762.513.454.004.82.373.82.827v1.426c0 .457-.37.827-.827.827a.827.827 0 0 0-.58 1.413.84.84 0 0 1 0 1.174l-1 1a.84.84 0 0 1-1.173 0 .787.787 0 0 0-.58-.247.827.827 0 0 0-.833.827c0 .456-.37.826-.827.826H7.183a.827.827 0 0 1-.826-.826.827.827 0 0 0-1.414-.58.84.84 0 0 1-1.173 0l-1-1a.84.84 0 0 1 0-1.174.827.827 0 0 0-.58-1.413.827.827 0 0 1-.827-.827V7.161c0-.457.37-.827.827-.827a.827.827 0 0 0 .58-1.413.84.84 0 0 1 0-1.174l1-1a.84.84 0 0 1 1.173 0c.15.16.361.25.58.247a.827.827 0 0 0 .834-.833c0-.457.37-.827.826-.827h1.434c.456 0 .826.37.826.827a.827.827 0 0 0 .834.833c.219.002.43-.087.58-.247a.84.84 0 0 1 1.173 0l1 1a.84.84 0 0 1 0 1.174.827.827 0 0 0-.182.9Zm-.951 3.72a1.813 1.813 0 0 1 1.54-1.12l-.02-1.094a1.813 1.813 0 0 1-1.52-1.12 1.84 1.84 0 0 1 .3-1.866l-.767-.767a1.8 1.8 0 0 1-1.16.42 1.84 1.84 0 0 1-1.827-1.66H7.35c-.09.942-.88 1.66-1.827 1.66a1.8 1.8 0 0 1-1.16-.42l-.746.767c.43.523.537 1.24.28 1.866a1.813 1.813 0 0 1-1.52 1.12v1.094c.676.059 1.263.491 1.52 1.12a1.84 1.84 0 0 1-.3 1.866l.766.767a1.8 1.8 0 0 1 1.16-.42 1.84 1.84 0 0 1 1.827 1.66h1.093a1.82 1.82 0 0 1 1.827-1.66 1.8 1.8 0 0 1 1.16.42l.747-.767a1.84 1.84 0 0 1-.28-1.866Z"
      clipRule="evenodd"
    />
    <path
      fill="#6B778C"
      fillRule="evenodd"
      d="M5.59 7.874a2.307 2.307 0 1 1 4.613 0 2.307 2.307 0 0 1-4.613 0Zm.973 0a1.333 1.333 0 1 0 2.667 0 1.333 1.333 0 0 0-2.667 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SettingsIcon;
