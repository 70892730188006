const ProductLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={24} fill="none">
    <rect width={17.86} height={17.86} fill="#51276B" rx={8} />
    <rect
      width={17.86}
      height={17.86}
      x={9.488}
      y={6.141}
      fill="#E5E5E5"
      rx={8}
    />
  </svg>
);
export default ProductLogo;
