import * as React from "react";
const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      fill="#343330"
      d="M19.281 18.22a.75.75 0 1 1-1.061 1.061L12 13.061l-6.219 6.22A.75.75 0 1 1 4.72 18.22L10.94 12 4.72 5.781A.75.75 0 1 1 5.78 4.72l6.22 6.22 6.219-6.22A.75.75 0 1 1 19.28 5.78l-6.22 6.22 6.22 6.219Z"
    />
  </svg>
);
export default CloseIcon;
