import { fetchData } from "./utils";

export const getBusinessObjects = async () => {
  const url = "/lego/sessions/";
  const response = await fetchData(url, "GET");
  if (response.data.bobjects) {
    return response.data.bobjects;
  } else {
    return null;
  }
};

export const getTableColumns = async (tableId) => {
  const url = `/lego/sessions/b-objects/${tableId}/b-fields/`;
  const response = await fetchData(url, "GET");
  if (response.data) {
    return response.data;
  } else {
    return null;
  }
};

export const getTableRows = async (tableId) => {
  const url = `/lego/_/b-objects/${tableId}/_/b-records/filter/`;
  const postObject = {};
  const response = await fetchData(url, "POST", postObject);
  if (response.data) {
    return response.data;
  } else {
    return null;
  }
};
