import * as React from "react";
const ExpandIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <path
      stroke="#6B778C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.667 11.333 12 7.999 8.667 4.666M3.999 11.333l3.333-3.334-3.333-3.333"
    />
  </svg>
);
export default ExpandIcon;
