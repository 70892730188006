import Box from "@mui/joy/Box";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";

const DropdownStyles = () => {
  return (
    <Box
      component="section"
      sx={{ p: 2, border: "1px dashed grey", width: "200px" }}
    >
      Neutral:
      <Select
        color="neutral"
        disabled={false}
        placeholder="Choose one…"
        variant="soft"
      >
        <Option value="dog">Dog</Option>
        <Option value="cat">Cat</Option>
        <Option value="fish">Fish</Option>
        <Option value="bird">Bird</Option>
      </Select>
      <br />
      <br />
      primary:
      <Select
        color="primary"
        disabled={false}
        placeholder="Choose one…"
        variant="soft"
      >
        <Option value="dog">Dog</Option>
        <Option value="cat">Cat</Option>
        <Option value="fish">Fish</Option>
        <Option value="bird">Bird</Option>
      </Select>
      <br />
      Secondary:
      <Select
        color="secondary"
        disabled={false}
        placeholder="Choose one…"
        variant="soft"
      >
        <Option value="dog">Dog</Option>
        <Option value="cat">Cat</Option>
        <Option value="fish">Fish</Option>
        <Option value="bird">Bird</Option>
      </Select>
      <br />
      Danger:
      <Select
        color="danger"
        disabled={false}
        placeholder="Choose one…"
        variant="soft"
      >
        <Option value="dog">Dog</Option>
        <Option value="cat">Cat</Option>
        <Option value="fish">Fish</Option>
        <Option value="bird">Bird</Option>
      </Select>
      <br />
      Success:
      <Select
        color="success"
        disabled={false}
        placeholder="Choose one…"
        variant="soft"
      >
        <Option value="dog">Dog</Option>
        <Option value="cat">Cat</Option>
        <Option value="fish">Fish</Option>
        <Option value="bird">Bird</Option>
      </Select>
      <br />
      Warning:
      <Select
        color="warning"
        disabled={false}
        placeholder="Choose one…"
        variant="soft"
      >
        <Option value="dog">Dog</Option>
        <Option value="cat">Cat</Option>
        <Option value="fish">Fish</Option>
        <Option value="bird">Bird</Option>
      </Select>
    </Box>
  );
};

export default DropdownStyles;
