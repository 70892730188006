import React from "react";
import { Input, Box } from "@mui/joy";
import SeachIcon from "../Icons/SeachIcon";
import Chip from "@mui/joy/Chip";
import CloseIcon from "../Icons/CloseIcon";

// Define the props interface
interface SeachBoxProps {
  setShowSearchBox: (show: boolean) => void;
}

const SeachBox: React.FC<SeachBoxProps> = ({ setShowSearchBox }) => {
  return (
    <Input
      autoFocus
      size="sm"
      color="neutral"
      variant="plain"
      placeholder="Search: MODULES, records, sheet etc."
      startDecorator={<SeachIcon />}
      endDecorator={
        <React.Fragment>
          <Chip color="neutral" variant="outlined" size="sm">
            esc
          </Chip>
          <Box
            onClick={() => {
              setShowSearchBox(false);
            }}
            sx={{
              marginLeft: "14px",
              cursor: "pointer",
            }}
          >
            <CloseIcon />
          </Box>
        </React.Fragment>
      }
      sx={{
        padding: "0px 16px",
        width: "100%",
        backgroundColor: "#F7F2F9",
        "&::before": {
          top: "unset",
        },
      }}
    />
  );
};

export default SeachBox;
