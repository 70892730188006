import * as React from "react";
const ImportIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none">
    <path
      fill="#3F3BE1"
      fillRule="evenodd"
      d="M12.561 5.7V3.135c-.018-1-.834-1.8-1.833-1.8H5.395c-1 0-1.816.8-1.834 1.8v.3a2.86 2.86 0 0 0-2.166 2.767v6a2.867 2.867 0 0 0 2.86 2.86h7.626a2.867 2.867 0 0 0 2.847-2.86V8.467a2.86 2.86 0 0 0-2.167-2.766ZM5.395 2.3h5.333c.46 0 .833.374.833.834v2.473H9.195a.273.273 0 0 1-.274-.273 2 2 0 0 0-2-2h-2.36v-.2c0-.46.373-.833.834-.833Zm6.486 11.9a2 2 0 0 0 2-2l-.02-3.733a2 2 0 0 0-2-2H9.195A1.133 1.133 0 0 1 8.06 5.334a1.133 1.133 0 0 0-1.153-1.133H4.24a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h7.64Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ImportIcon;
