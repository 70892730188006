import * as React from "react";
const ConnectedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} fill="none">
    <path
      fill="#42055F"
      fillRule="evenodd"
      d="M10.98.107H5.02A5.02 5.02 0 0 0 0 5.128v5.958a5.02 5.02 0 0 0 5.02 5.021h5.96a5.02 5.02 0 0 0 5.02-5.02V5.127a5.02 5.02 0 0 0-5.02-5.02ZM6.534 7.413a.61.61 0 0 1-.444-.143.62.62 0 0 1 0-.887l.41-.41h-.736c-.897 0-1.623.727-1.623 1.624V8.7c.022.88.743 1.582 1.623 1.582a.628.628 0 0 1 0 1.255A2.887 2.887 0 0 1 2.886 8.66V7.555a2.887 2.887 0 0 1 2.879-2.879h.736l-.41-.41a.62.62 0 0 1 0-.887.628.628 0 0 1 .887 0L8.46 4.86a.686.686 0 0 1 .133.21.594.594 0 0 1 0 .477.56.56 0 0 1-.133.2L6.978 7.27a.62.62 0 0 1-.443.143Zm3.699 4.125a2.887 2.887 0 0 0 2.878-2.878V7.555a2.887 2.887 0 0 0-2.878-2.879.628.628 0 1 0 0 1.256c.896 0 1.623.726 1.623 1.623V8.66c0 .896-.727 1.623-1.623 1.623h-.737l.41-.41a.628.628 0 0 0-.887-.887l-1.48 1.481a.561.561 0 0 0-.135.201.67.67 0 0 0 0 .477.686.686 0 0 0 .134.21l1.481 1.48c.12.115.278.18.444.184a.644.644 0 0 0 .443-.184.628.628 0 0 0 0-.895l-.41-.402h.737Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ConnectedIcon;
