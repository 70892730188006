import React, { useState } from "react";
import {
  LeftHeaderBox,
  AppSwitcherBox,
  AppLogoBox,
  ProductTitle,
  ProductLogoBox,
} from "./styles";
import AppSwitchIcon from "../Icons/AppSwitchIcon";
import ProductLogo from "../Icons/ProductLogo";
import Drawer from "@mui/joy/Drawer";
import AppSelector from "../AppSelector";

const LeftHeader: React.FC = () => {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <LeftHeaderBox>
      <AppSwitcherBox onClick={() => setShowDrawer(true)}>
        <AppSwitchIcon />
      </AppSwitcherBox>
      <AppLogoBox>
        <ProductLogoBox>
          <ProductLogo />
        </ProductLogoBox>
        <ProductTitle>Zinghub</ProductTitle>
      </AppLogoBox>

      <Drawer
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        sx={{
          "& .MuiDrawer-content": {
            width: 232,
          },
        }}
      >
        <AppSelector />
      </Drawer>
    </LeftHeaderBox>
  );
};

export default LeftHeader;
