import React from "react";
import { HeaderBox } from "./styles";
import LeftHeader from "./LeftHeader";
import RightHeader from "./RightHeader";

const AppHeader: React.FC = () => {
  return (
    <>
      <HeaderBox>
        <LeftHeader />
        <RightHeader />
      </HeaderBox>
    </>
  );
};

export default AppHeader;
