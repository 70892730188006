import styled from "styled-components";

export const WorkspaceBox = styled.div`
  flex: 1;
  padding: 32px;
  overflow-x: auto;
`;

export const WorkspaceHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: #5f49dd;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleText = styled.div`
  margin-left: 16px;
`;

export const ActionsBox = styled.div``;

export const WorkspaceBody = styled.div`
  margin-top: 18px;
  border-radius: 8px;
  border: 1px solid #f5effc;
`;

export const TableContainer = styled.div`
  height: calc(100vh - 230px);
  overflow-y: auto;
  overflow-x: auto;
`;

export const TableToolbar = styled.div`
  height: 56px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RightToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LeftToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ToolbarItem = styled.div`
  margin-left: 12px;
`;
