import * as React from "react";
const SortIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={12} fill="none">
    <path
      fill="#6B778C"
      d="m13.894 6.84-3.093-3.1a.527.527 0 0 0-.713 0l-3.074 3.1a.5.5 0 0 0 .667.706l2.147-2.147v5.934a.5.5 0 0 0 1 0V5.206l2.34 2.34a.5.5 0 0 0 .706 0 .5.5 0 0 0 .02-.707ZM7.014 4.313a.5.5 0 0 0-.706 0L4.18 6.459V.666a.5.5 0 0 0-1 0v6L.84 4.313a.5.5 0 0 0-.707.706l3.094 3.1a.507.507 0 0 0 .713 0l3.073-3.1a.5.5 0 0 0 0-.706Z"
    />
  </svg>
);
export default SortIcon;
