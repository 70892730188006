import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { NavBox, NavActions, NavActionMenu } from "./styles";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import ContactIcon from "../Icons/ContactIcon";
import LeadsIcon from "../Icons/LeadsIcon";
import DealsIcon from "../Icons/DealsIcon";
import InboxIcon from "../Icons/InboxIcon";
import ActivityIcon from "../Icons/ActivityIcon";
import ExpandIcon from "../Icons/ExpandIcon";
import CollapseIcon from "../Icons/CollapseIcon";
import { getBusinessObjects } from "../Services";
import { useNavigate } from "react-router-dom";

const MODULES = [
  {
    id: 1,
    icon: <ContactIcon />,
    title: "Contacts",
  },
  {
    id: 2,
    icon: <LeadsIcon />,
    title: "Leads",
  },
  {
    id: 3,
    icon: <DealsIcon />,
    title: "Deals",
  },
  {
    id: 4,
    icon: <InboxIcon />,
    title: "Sales Inbox",
  },
  {
    id: 5,
    icon: <ActivityIcon />,
    title: "Activities",
  },
];
const AppNavigation = ({ setItemSelected }) => {
  const navigate = useNavigate();

  const query = useQuery({
    queryKey: ["getBusinessObjects"],
    queryFn: getBusinessObjects,
  });
  const { data } = query;

  const [isCollapsed, setIsCollapsed] = useState(false);

  const navigateToTableView = (item) => {
    setItemSelected(item.label);
    navigate(`/table-view/${item.internalName}`);
  };

  return (
    <NavBox style={isCollapsed ? { width: "65px" } : { width: "232px" }}>
      <NavActions>
        <NavActionMenu
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
        >
          {isCollapsed ? <ExpandIcon /> : <CollapseIcon />}
        </NavActionMenu>
      </NavActions>
      {!isCollapsed && (
        <Typography
          id="decorated-list-demo"
          level="body-xs"
          textTransform="uppercase"
          fontWeight="lg"
          mb={1}
        >
          MODULES
        </Typography>
      )}
      <List size="sm">
        {data?.map((item, index) => (
          <ListItem key={index} onClick={() => navigateToTableView(item)}>
            <ListItemButton color="primary" variant="plain">
              <ListItemDecorator>
                <ActivityIcon />
              </ListItemDecorator>
              {!isCollapsed && <ListItemContent>{item.label}</ListItemContent>}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </NavBox>
  );
};

export default AppNavigation;
