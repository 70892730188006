import * as React from "react";
const FilterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none">
    <path
      fill="#6B778C"
      fillRule="evenodd"
      d="M3.338 1.334h9.453v.007a1.933 1.933 0 0 1 1.367 3.333l-3.18 3.18a1.94 1.94 0 0 0-.567 1.334v2.933a1.94 1.94 0 0 1-.773 1.547l-.827.62a1.927 1.927 0 0 1-3.093-1.547V9.188a1.94 1.94 0 0 0-.567-1.334l-3.18-3.18a1.933 1.933 0 0 1 1.367-3.34Zm10.32 1.58a.933.933 0 0 0-.867-.58H3.338a.933.933 0 0 0-.867.58.92.92 0 0 0 .207 1.02l3.193 3.18c.553.548.862 1.295.86 2.074v3.553a.927.927 0 0 0 .913.94.9.9 0 0 0 .554-.193l.826-.62a.933.933 0 0 0 .374-.747V9.188c0-.78.315-1.528.873-2.074l3.18-3.18a.92.92 0 0 0 .207-1.02Z"
      clipRule="evenodd"
    />
  </svg>
);
export default FilterIcon;
